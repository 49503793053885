body {
    background: radial-gradient(circle, #373774, #222244);
    color: #e0e0e0;
    margin: 10px;
}

.vertical-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.horizontal-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    gap: 20px;
}

.button {
    background-color: #222222;
    color: #e0e0e0;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 8px;
    margin: 8px;
    font-size: 16px;
    cursor: pointer;
}

.board {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
}

.square {
    width: 100%;
    height: 100%;
    display: flex;
    text-align: start;
    align-items: flex-end;
    background-size: cover;
    box-sizing: border-box;
    position: relative;
    outline-offset: -3px;
    user-select: none;
}

.square.light {
    background-color: #f0d9b5;
    color: #b58863;
}

.square.dark {
    background-color: #b58863;
    color: #f0d9b5;
}

.square-name {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px;
    font-size: 12px;

}

.chess-piece {
    transform: translate(0, 0);
}

.material-indicator {
    width: 100%;
    text-align: left;
    margin: 5px;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
}

.timer {
    width: 100%;
    text-align: right;
    margin: 5px;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
}

.timer-value {
    font-size: 30px;
    font-weight: bold;
}

.timer-label {
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
    align-items: center;
    display: flex;
    overflow: hidden;
    text-wrap: nowrap;
}

.new-game-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
}

.new-game-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 60%;
    min-height: 60%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.how-to-play-modal {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
}

.how-to-play-modal-content {
    width: 60%;
    max-height: 80%;
    background-color: black;
    align-items: center;
    display: flex; 
    flex-direction: column;
    overflow: auto;
    text-align: left;
    box-sizing: border-box;
}

.how-to-play-paragraph {
    align-self: center;
    padding: 10px;
    font-size: 20px;
    max-width: 75%;
    text-align: left;
}

.label-and-value {
    min-width: 60%;
    min-height: 10%;
    background-color: gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    padding: 10px;
}

.label-and-value-item {
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 24px;
}

.handicap-info {
    text-align: center;
}

.handicap {
    font-weight: bold;
    padding: 5px;
}

.message {
    font-weight: bold;
    padding: 5px;
}

.message-text {
    padding: 5px;
}

.promotion-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
}

.promotion-option {
    outline-offset: -3px;
}

@font-face {
    font-family: 'Cases';
    src: url('./fonts/CASEFONT.ttf') format('truetype');
}

.move:hover {
    background-color: #007700; /* Change this to your preferred hover color */
}

.square-rank {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}

.square-file {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}

.triangle-top-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid red;
    border-left: 10px solid red;
}

.triangle-top-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid red;
    border-right: 10px solid red;
}

.triangle-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid red;
    border-left: 10px solid red;
}

.triangle-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid red;
    border-right: 10px solid red;
}

@keyframes flash {
    0% { color: white; }
    50% { color: red; }
    100% { color: white; }
}

.flash {
    animation: flash 0.3s linear;
}

@keyframes reverse-flash {
    0% { color: red; }
    50% { color: white; }
    100% { color: red; }
}

.reverse-flash {
    animation: reverse-flash 0.3s linear;
}

/* 
.score-sheet {
    display: flex;
    flex-direction: column;
    max-height: 100; 
    overflow-y: auto;
}

.move-row {
    display: flex;
    justify-content: space-between;
}

.move {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    height: 25px;
    flex: 1;
} */

@keyframes pulseSize {
    0% {
        transform: scale(1);
    }

    35% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.02);
    }

    65% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}
