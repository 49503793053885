
@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap');

.custom-button {
    font-size: 20px;
    padding: 20px;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 0 9px #999;
}

.custom-button:hover {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}